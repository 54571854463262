import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const SubClaseService = {
	Listar: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Sub_Clase_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_cat_subclase: null,
			id_cat_clase: form.id_cat_clase,
			clave: form.clave,
			subclase: form.subclase,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Sub_Clase_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_cat_subclase: form.id_cat_subclase,
			id_cat_clase: form.id_cat_clase,
			clave: form.clave,
			subclase: form.subclase,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Sub_Clase_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cat_subclase) => {
		let params = {
			id_cat_subclase: id_cat_subclase
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Sub_Clase_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
