import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const ProveedorService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				clave: filtro.clave || null,
				nombre_razon_social: filtro.nombre_razon_social || null,
				nombre_comercial: filtro.nombre_comercial || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Proveedor_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		
		let params = {
			id_proveedor: form.id_proveedor || '',
			clave: form.clave || '',
			nombre_razon_social: form.nombre_razon_social || '',
			rfc: form.rfc || '',
			nombre_comercial: form.nombre_comercial || '',
			id_cat_tipo_persona: form.id_cat_tipo_persona || '',
			correo_electronico: form.correo_electronico || '',
			telefono: form.telefono || '',
			
			id_proveedor_direccion: form.id_proveedor_direccion || '',
			id_cat_estado: form.id_cat_estado,
			id_cat_municipio: form.id_cat_municipio,
			calle: form.calle,
			numero_exterior: form.numero_exterior,
			numero_interior: form.numero_interior,
			codigo_postal: form.codigo_postal,
			colonia: form.colonia,
			referencia: form.referencia,
			usuarios_autorizados: form.usuarios_autorizados,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Proveedor_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_proveedor: form.id_proveedor || '',
			clave: form.clave || '',
			nombre_razon_social: form.nombre_razon_social || '',
			rfc: form.rfc || '',
			nombre_comercial: form.nombre_comercial || '',
			id_cat_tipo_persona: form.id_cat_tipo_persona || '',
			correo_electronico: form.correo_electronico || '',
			telefono: form.telefono || '',
			
			id_proveedor_direccion: form.id_proveedor_direccion || '',
			id_cat_estado: form.id_cat_estado,
			id_cat_municipio: form.id_cat_municipio,
			calle: form.calle,
			numero_exterior: form.numero_exterior,
			numero_interior: form.numero_interior,
			codigo_postal: form.codigo_postal,
			colonia: form.colonia,
			referencia: form.referencia,
			usuarios_autorizados: form.usuarios_autorizados,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Proveedor_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_proveedor) => {
		let params = {
			id_proveedor: id_proveedor
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Proveedor_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
