import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const CuentaBancariaService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				id_cat_banco: filtro.id_cat_banco || null,
				id_proveedor: filtro.id_proveedor || null,
				nombre_comercial: filtro.nombre_comercial || null,
				rfc: filtro.rfc || null,
				id_cat_area_proyecto: filtro.id_cat_area_proyecto || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cuenta_Bancaria_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		
		let params = {
			id_cuenta_bancaria: form.id_cuenta_bancaria,
			id_proveedor: form.id_proveedor,
			nombre_comercial: form.nombre_comercial,
			nombre_recibe_pago: form.nombre_recibe_pago,
			rfc: form.rfc,
			id_cat_banco: form.id_cat_banco,
			numero_cuenta: form.numero_cuenta,
			numero_tarjeta: form.numero_tarjeta,
			clabe: form.clabe,
			id_cat_area_proyecto: form.id_cat_area_proyecto,
			
			id_cat_estado: form.id_cat_estado,
			id_cat_municipio: form.id_cat_municipio,
			calle: form.calle,
			numero_exterior: form.numero_exterior,
			numero_interior: form.numero_interior,
			codigo_postal: form.codigo_postal,
			colonia: form.colonia,
			referencia: form.referencia,
			usuarios_autorizados: form.usuarios_autorizados,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cuenta_Bancaria_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_cuenta_bancaria: form.id_cuenta_bancaria,
			id_proveedor: form.id_proveedor,
			nombre_comercial: form.nombre_comercial,
			nombre_recibe_pago: form.nombre_recibe_pago,
			rfc: form.rfc,
			id_cat_banco: form.id_cat_banco,
			numero_cuenta: form.numero_cuenta,
			numero_tarjeta: form.numero_tarjeta,
			clabe: form.clabe,
			id_cat_area_proyecto: form.id_cat_area_proyecto,
			
			id_cat_estado: form.id_cat_estado,
			id_cat_municipio: form.id_cat_municipio,
			calle: form.calle,
			numero_exterior: form.numero_exterior,
			numero_interior: form.numero_interior,
			codigo_postal: form.codigo_postal,
			colonia: form.colonia,
			referencia: form.referencia,
			usuarios_autorizados: form.usuarios_autorizados,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cuenta_Bancaria_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cuenta_bancaria) => {
		let params = {
			id_cuenta_bancaria: id_cuenta_bancaria
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cuenta_Bancaria_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
