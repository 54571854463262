import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const ClaseService = {
	Listar: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Clase_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			clase: form.clase,
			clave: form.clave,
			abreviatura: form.abreviatura,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Clase_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_cat_clase: form.id_cat_clase,
			clase: form.clase,
			clave: form.clave,
			abreviatura: form.abreviatura,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Clase_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cat_clase) => {
		let params = {
			id_cat_clase: id_cat_clase
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Clase_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
