import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const TipoPolizaService = {
	Listar: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Tipo_Poliza_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_cat_tipo_poliza: null,
			clave: form.clave,
			tipo_poliza: form.tipo_poliza,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Tipo_Poliza_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_cat_tipo_poliza: form.id_cat_tipo_poliza,
			clave: form.clave,
			tipo_poliza: form.tipo_poliza,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Tipo_Poliza_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cat_tipo_poliza) => {
		let params = {
			id_cat_tipo_poliza: id_cat_tipo_poliza
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Tipo_Poliza_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
