import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

class BancoSatService {
	static Listar = () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Banco_Sat_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	static Agregar = (form) => {
		let params = {
			id_cat_banco_sat: null,
			clave: form.clave,
			nombre_razon_social: form.nombre_razon_social,
			descripcion: form.descripcion,
			logo_archivo: form.logo_archivo,
			logo_formato: form.logo_formato,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Banco_Sat_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	static Modificar = (form) => {
		let params = {
			id_cat_banco_sat: form.id_cat_banco_sat,
			clave: form.clave,
			nombre_razon_social: form.nombre_razon_social,
			descripcion: form.descripcion,
			logo_archivo: form.logo_archivo,
			logo_formato: form.logo_formato,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Banco_Sat_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	static Eliminar = (id_cat_banco_sat) => {
		let params = {
			id_cat_banco_sat: id_cat_banco_sat
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Banco_Sat_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
}

export default BancoSatService;
