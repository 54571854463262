import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const TipoGastoService = {
	Listar: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Tipo_Gasto_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_cat_tipo_gasto: null,
			tipo_gasto: form.tipo_gasto,
			clave: form.clave,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Tipo_Gasto_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_cat_tipo_gasto: form.id_cat_tipo_gasto,
			tipo_gasto: form.tipo_gasto,
			clave: form.clave,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Tipo_Gasto_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cat_tipo_gasto) => {
		let params = {
			id_cat_tipo_gasto: id_cat_tipo_gasto
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Tipo_Gasto_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
