import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const AreaProyectoService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				area_proyecto: filtro.area_proyecto || null,
				clave: filtro.clave || null,
				tipo_proyecto: filtro.tipo_proyecto || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : 'all',
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Area_Proyecto_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			area_proyecto: form.area_proyecto,
			clave: form.clave,
			tipo_proyecto: form.tipo_proyecto,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Area_Proyecto_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_cat_area_proyecto: form.id_cat_area_proyecto,
			area_proyecto: form.area_proyecto,
			clave: form.clave,
			tipo_proyecto: form.tipo_proyecto,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Area_Proyecto_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cat_area_proyecto) => {
		let params = {
			id_cat_area_proyecto: id_cat_area_proyecto
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Area_Proyecto_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
